import React, { useState, useEffect, useRef } from "react";
import { translate, getLocale } from "../../translations/localization";
import "./styles.css";

function ReservationBanner() {
  const [rooms, setRooms] = useState([{ id: 1, adults: 2, children: 0, babies: 0 }]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const formRef = useRef(null);

  // Function to update guests count text
  const updateGuestText = () => {
    const adults = rooms.reduce((sum, room) => sum + room.adults, 0);
    const children = rooms.reduce((sum, room) => sum + room.children, 0);
    const babies = rooms.reduce((sum, room) => sum + room.babies, 0);
    return `${adults} ${translate("tAdults")}, ${children} ${translate("tChildren")}, ${babies} ${translate("tBabies")}`;
  };

  // Function to add a new room
  const addRoom = () => {
    setRooms([...rooms, { id: rooms.length + 1, adults: 2, children: 0, babies: 0 }]);
  };

  // Function to remove a room
  const removeRoom = (roomId) => {
    setRooms(rooms.filter((room) => room.id !== roomId));
  };

  // Function to update guest count
  const changeGuests = (roomId, type, delta) => {
    setRooms(
      rooms.map((room) =>
        room.id === roomId
          ? { ...room, [type]: Math.max(0, room[type] + delta) }
          : room
      )
    );
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // Close dropdown
  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  // Initialize Clock PMS after component mounts
  useEffect(() => {
    window.clockPmsWbeInit({
      wbeBaseUrl: "https://sky-eu1.clock-software.com/spa/pms-wbe/#/hotel/15621",
      defaultMode: "standard",
      roundedCorners: true,
      language: getLocale(),
    });

    // Handle form submission for Clock PMS
    if (formRef.current) {
      formRef.current.addEventListener("submit", (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        window.clockPmsWbeShow({
          arrival: formData.get("arrival"),
          departure: formData.get("departure"),
          adults: rooms.reduce((sum, room) => sum + room.adults, 0),
          children: rooms.reduce((sum, room) => sum + room.children, 0),
          bonusCode: formData.get("bonusCode"),
          roomTypeIds: [],
          rateIds: [],
          submit: true,
        });
      });
    }
  }, [rooms]);

  return (
    <div className="reservation-banner-container">
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="d-md-none">{translate("tBookingForm")}</div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse2"
          aria-controls="navbarCollapse2"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse2">
          <form ref={formRef} data-clock-pms-wbe-form="">
            <div className="row align-items-center g-2">
              {/* Arrival Date */}
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-auto">
                <div className="form-floating">
                  <input type="date" className="form-control" id="floatingArrival" name="arrival" />
                  <label htmlFor="floatingArrival" className="text-dark">{translate("tArrival")}</label>
                </div>
              </div>

              {/* Departure Date */}
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-auto">
                <div className="form-floating">
                  <input type="date" className="form-control" id="floatingDeparture" name="departure" />
                  <label htmlFor="floatingDeparture" className="text-dark">{translate("tDeparture")}</label>
                </div>
              </div>

              {/* Bonus Code */}
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-auto">
                <div className="form-floating">
                  <input type="text" className="form-control" id="form-bonus-code" placeholder="Code" name="bonusCode" />
                  <label htmlFor="form-bonus-code" className="text-dark">{translate("tBonusCode")}</label>
                </div>
              </div>

              {/* Guest Selector */}
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-auto position-relative">
                <button type="button" className="btn btn-light" onClick={toggleDropdown} style={{ minHeight: "58px", fontWeight: '300' }}>
                  {translate("tGuests")} <span>{updateGuestText()}</span>
                </button>
                {isDropdownVisible && (
                  <div className="dropdown-menu p-3 show" style={{ minWidth: "300px" }} id="guest-dropdown">
                    <div id="rooms-container">
                      {rooms.map((room) => (
                        <div className="room" key={room.id}>
                          <div className="d-flex justify-content-between align-items-center">
                            <strong>{translate("tRoom")} {room.id}</strong>
                            {rooms.length > 1 && (
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => removeRoom(room.id)}>
                                <i className="bi bi-trash"></i>
                              </button>
                            )}
                          </div>

                          <label className="form-label">{translate("tAdults")}</label>
                          <div className="input-group mb-2">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "adults", -1)}>-</button>
                            <input type="text" className="form-control text-center" value={room.adults} readOnly />
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "adults", 1)}>+</button>
                          </div>

                          <label className="form-label">{translate("tChildren")}</label>
                          <div className="input-group mb-2">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "children", -1)}>-</button>
                            <input type="text" className="form-control text-center" value={room.children} readOnly />
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "children", 1)}>+</button>
                          </div>

                          <label className="form-label">{translate("tBabies")}</label>
                          <div className="input-group mb-2">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "babies", -1)}>-</button>
                            <input type="text" className="form-control text-center" value={room.babies} readOnly />
                            <button type="button" className="btn btn-outline-secondary" onClick={() => changeGuests(room.id, "babies", 1)}>+</button>
                          </div>

                          <textarea className="form-control" placeholder={translate("tPreferences")} rows="2"></textarea>

                        </div>
                      ))}
                    </div>
                    <button type="button" className="btn btn-outline-success w-100 mb-2" onClick={addRoom}>{translate("tAddRoom")}</button>
                    <button type="button" className="btn btn-primary w-100 mt-2" onClick={closeDropdown}>{translate("tDone")}</button>
                  </div>
                )}
              </div>

              <button type="submit" className="btn btn-light" style={{ minHeight: "58px", height: 'auto', fontFamily: 'Monsterrat', fontWeight: '900' }}>{translate("tBookNow")}</button>
            </div>
          </form>
        </div>
      </nav>
    </div>
  );
}

export default ReservationBanner;
