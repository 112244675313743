import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ScrollUpButton from "react-scroll-up-button";
import { translate } from "../../translations/localization.js";
import { Socials } from "../../components/Header";

import "./styles.css";

class Footer extends Component {
  render() {
    const { language } = this.props;
    console.log('fields', this.props);
    return (
      <div className="footer footer-overflay">
        <div className="container">
          <div className="col-logo">
            <Link to={`/${language}`} className="logo">
              <img alt="Apolonia Resort Hotel" src="/apolonia-resort-hotel.png" />
            </Link>
          </div>
          <div className="col-info">
            <span>{this.props.fields && this.props.fields.address}</span>
            <span>{this.props.fields && this.props.fields.email}</span>
            <br />
            <span>
              {translate("tReception")}: {this.props.fields && this.props.phones.split(";")[0].split(":")[1]}
            </span>
            <span>
              {translate("tReservations")}:{" "}
              {this.props.fields &&
                this.props.phones
                  .split(";")
                  .slice(1)
                  .map((phone, i) => (i > 0 ? phone.split(":")[1] : phone.split(":")[1] + ", "))}{" "}
              (08:00 - 18:00) 
            </span>
          </div>
          <div className="col-socials">
            <Socials />
          </div>
          <div className="col-copy">
            {translate("footerCopyright", { currentYear: `${new Date().getFullYear()}` })}
          </div>
          </div>

        <div className="footer-bg" />

        <ScrollUpButton ContainerClassName="back-to-top">
          <span>
            <img src="/icons/up.svg" alt="^" />
          </span>
        </ScrollUpButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Footer);
