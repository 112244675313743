import React, { useEffect } from "react";
import "./styles.css";

const VideoBackground = () => {
  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      tag.async = true;
      tag.defer = true;
      document.body.appendChild(tag);
    }

    const onYouTubeIframeAPIReady = () => {
      new window.YT.Player("yt-player", {
        videoId: "yT3wfdl-rJQ",
        playerVars: {
          autoplay: 1,
          mute: 1,
          loop: 1,
          playlist: "yT3wfdl-rJQ",
          controls: 0, // Disable controls (play, pause, etc.)
          modestbranding: 1, // Hide YouTube logo
          rel: 0, // Disable related videos at the end
          showinfo: 0, // Hide video info (title, uploader)
          iv_load_policy: 3, // Disable annotations
          disablekb: 1, // Disable keyboard controls
        },
        events: {
          onReady: (event) => {
            event.target.setPlaybackQuality("hd1080");
            event.target.playVideo(); // Start playback immediately
          },
        },
      });
    };

    // Ensure YouTube API is available before initializing
    if (window.YT && window.YT.Player) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }
  }, []);

  return (
    <div className="video-background">
      <div className="video-foreground ratio ratio-16x9">
        <div id="yt-player"></div>
      </div>
    </div>
  );
};

export default VideoBackground;
