import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './config/stores';
import Routes from './components/routes/Routes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import i18next from 'i18n-js'; 

import './App.css';

class App extends Component {
  render() {
    let store = configureStore();
    const currentLang = i18next.language || 'bg'; // Default language set to 'bg'

    const ogData = {
      title: i18next.translate('meta.title'),
      description: i18next.translate('meta.description'),
      url: 'https://www.yourwebsite.com',
      image: 'https://apoloniaresort.com/imgs/fb-share-1200x630.jpg',
      siteName: 'APOLONIA RESORT HOTEL',
    };

    return (
      <Provider store={store}>
          <Routes />
      </Provider>
    );
  }
}

export default App;