import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Footer } from "../../components";
import { translate } from "../../translations/localization.js";
import { getData } from "../../actions";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./styles.css";

const photosEvents = [
  "/imgs/apolonia-gallery-events-1.jpg",
  "/imgs/apolonia-gallery-events-2.jpg",
  "/imgs/apolonia-gallery-events-3.jpg",
  "/imgs/apolonia-gallery-events-4.jpg",
  "/imgs/apolonia-gallery-events-5.jpg",
  "/imgs/apolonia-gallery-events-6.jpg",
  "/imgs/apolonia-gallery-events-7.jpg",
  "/imgs/apolonia-gallery-events-8.jpg",
  "/imgs/apolonia-gallery-events-9.jpg",
];

const LazyImage = ({ src, alt, onClick }) => (
  <img
    src={src}
    alt={alt}
    loading="lazy"
    className="gallery-img"
    onClick={onClick}
  />
);

const NewGallery = ({ language, sections, getData }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photosApoloniaResort, setPhotosApoloniaResort] = useState([]);
  const [photosBeachCommonAreas, setPhotosBeachCommonAreas] = useState([]);
  const [isOpen, setIsOpen] = useState({ resort: false, beach: false, events: false });

  useEffect(() => {
    getData({
      page: "gallery",
      onSuccess: (res) => {
        setPhotosApoloniaResort(res?.find((sect) => sect.for === "events")?.fields?.images || []);
        setPhotosBeachCommonAreas(res?.find((sect) => sect.for === "beach")?.fields?.images || []);
      },
    });
  }, [getData]);

  const openLightbox = (type, index) => {
    setIsOpen({ resort: type === "resort", beach: type === "beach", events: type === "events" });
    setPhotoIndex(index);
  };

  const renderGallery = (title, photos, type) => (
    <div className="gallery">
      <h2>{translate(title)}</h2>
      <div className="gallery-photos">
        {photos?.map((photo, i) => (
          <div key={i} onClick={() => openLightbox(type, i)}>
            <LazyImage src={photo} alt={title} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="wrap-all page-gallery">
      <Header fields={sections.find((sect) => sect.for === "header")} />
      <div className="main">
        <div className="container">
          {isOpen.resort && (
            <Lightbox
              mainSrc={photosApoloniaResort[photoIndex]}
              nextSrc={photosApoloniaResort[(photoIndex + 1) % photosApoloniaResort.length]}
              prevSrc={photosApoloniaResort[(photoIndex + photosApoloniaResort.length - 1) % photosApoloniaResort.length]}
              onCloseRequest={() => setIsOpen({ ...isOpen, resort: false })}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosApoloniaResort.length - 1) % photosApoloniaResort.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosApoloniaResort.length)}
            />
          )}

          {isOpen.beach && (
            <Lightbox
              mainSrc={photosBeachCommonAreas[photoIndex]}
              nextSrc={photosBeachCommonAreas[(photoIndex + 1) % photosBeachCommonAreas.length]}
              prevSrc={photosBeachCommonAreas[(photoIndex + photosBeachCommonAreas.length - 1) % photosBeachCommonAreas.length]}
              onCloseRequest={() => setIsOpen({ ...isOpen, beach: false })}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosBeachCommonAreas.length - 1) % photosBeachCommonAreas.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosBeachCommonAreas.length)}
            />
          )}

          {isOpen.events && (
            <Lightbox
              mainSrc={photosEvents[photoIndex]}
              nextSrc={photosEvents[(photoIndex + 1) % photosEvents.length]}
              prevSrc={photosEvents[(photoIndex + photosEvents.length - 1) % photosEvents.length]}
              onCloseRequest={() => setIsOpen({ ...isOpen, events: false })}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photosEvents.length - 1) % photosEvents.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photosEvents.length)}
            />
          )}

          {renderGallery("tApoloniaResort", photosApoloniaResort, "resort")}
          {renderGallery("tBeachCommonAreas", photosBeachCommonAreas, "beach")}
          {renderGallery("tEvents", photosEvents, "events")}
        </div>
      </div>
      <Footer fields={sections?.filter((sect) => sect.for === "footer")[0]?.fields} phones={sections.filter((sect) => sect.for === "header")[0]?.fields?.phone} />
      </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  sections: state.data.sections,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (payload) => dispatch(getData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGallery);
