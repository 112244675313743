import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "../../translations/localization.js";
import { history } from "../../config/stores";
import { NavLangs } from "../../components";
import "./styles.css";

class NavMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    const { language } = this.props;

    return (
      <div className="nav-main-wrap">
        <ul className="nav-main">
          <li className="item" activeclassname="active">
            <NavLink to={`/${language}/about-us`}>{translate("tAboutUs")}</NavLink>
          </li>
          <li className="item-hover" activeclassname="active">
            <NavLink to={`/${language}/room-types`}>{translate("tAccommodation")}</NavLink>
            {window.innerWidth > 900 && (
              <div className="hover-menu">
                <p
                  onClick={() => {
                    history.push(`/${language}/room-types/studio`);
                  }}
                >
                  {translate("studio")}
                </p>
                <p
                  onClick={() => {
                    history.push(`/${language}/room-types/double-room`);
                  }}
                >
                  {translate("doubleRoom")}
                </p>
                <p
                  onClick={() => {
                    history.push(`/${language}/room-types/apartment`);
                  }}
                >
                  {translate("apartment")}
                </p>
                <p
                  onClick={() => {
                    history.push(`/${language}/room-types/apartment-double`);
                  }}
                >
                  {translate("doubleApartment")}
                </p>
              </div>
            )}
          </li>
          <li className="item" activeclassname="active">
            <NavLink to={`/${language}/restaurants-and-bars`}>{translate("tRestaurantsAndBars")}</NavLink>
          </li>
          <li className="item" activeclassname="active">
            <NavLink to={`/${language}/sport-and-spa`}>{translate("tSportAndSpa")}</NavLink>
          </li>
          <li className="item" activeclassname="active">
            <NavLink to={`/${language}/gallery`}>{translate("tGallery")}</NavLink>
          </li>
          {/* <li className="item" activeclassname="active">
            <NavLink to={`/${language}/events`}>{translate("activities")}</NavLink>
          </li> */}
          <li className="item">
            <NavLangs />
          </li>
          <div className="reservation-button" data-clock-pms-wbe-button>
            {translate("reserveNow")}
          </div>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(NavMain);
